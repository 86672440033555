var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box box--filled",
      class: {
        "box--danger": _vm.severity === "error",
        "box--warning": _vm.severity === "warning",
        "box--info": _vm.severity === "info",
      },
    },
    [
      _c("icon", { staticClass: "box__icon", attrs: { name: _vm.icon } }),
      _c("h1", { staticClass: "box__title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("p", { staticClass: "box__description" }, [
        _vm._v(" " + _vm._s(_vm.description)),
      ]),
      _c(
        "router-link",
        {
          staticClass: "btn btn--white btn--sm",
          attrs: { to: { name: _vm.link.to } },
        },
        [_vm._v(_vm._s(_vm.link.text))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }