var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm._m(0),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container h-textCenter" }, [
        !_vm.hasEliteSubscription
          ? _c("div", { staticClass: "box box--filled banner" }, [
              _c("h2", [_vm._v("Take your beat store to the next level")]),
              _c("p", [
                _vm._v(
                  "Upgrade to an Elite membership and try it for free for 30 days!"
                ),
              ]),
            ])
          : _c("div", [
              _c("h2", [_vm._v("Account Subscription")]),
              _c("p", [
                _vm._v("You have an "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.authenticatedUser.subscription_type) + " "),
                ]),
                _vm._v(" subscription active."),
              ]),
              _c("p", [
                _vm._v("Next billing date is "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(
                        _vm.authenticatedUser.subscription_renewal_date,
                        "MMMM Do, YYYY"
                      )
                    )
                  ),
                ]),
                _vm._v("."),
              ]),
            ]),
        _c("div", { staticClass: "divider" }),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container container--sm" }, [
        _c(
          "div",
          { staticClass: "billToggle" },
          [
            _c("p", { class: { active: !_vm.billYearly } }, [
              _vm._v("Bill Monthly"),
            ]),
            _c("toggle-button", {
              attrs: {
                color: {
                  checked: "#0269FF",
                  unchecked: "#0269FF",
                  disabled: "#CCCCCC",
                },
                labels: false,
              },
              model: {
                value: _vm.billYearly,
                callback: function ($$v) {
                  _vm.billYearly = $$v
                },
                expression: "billYearly",
              },
            }),
            _c("p", { class: { active: _vm.billYearly } }, [
              _vm._v("Bill yearly"),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "container container--sm grid grid__col2" }, [
        _c("div", { staticClass: "pricingTable" }, [
          _vm._m(1),
          _vm._m(2),
          !_vm.hasEliteSubscription
            ? _c("button", { staticClass: "btn btn--currentPlan" }, [
                _vm._v("Current Plan"),
              ])
            : _c(
                "button",
                {
                  staticClass: "btn btn--black",
                  on: {
                    click: function ($event) {
                      return _vm.SET_ACTIVE_MODAL({
                        modal: _vm.ModalType.Subscribe,
                      })
                    },
                  },
                },
                [_vm._v("Downgrade Plan")]
              ),
        ]),
        _c("div", { staticClass: "pricingTable" }, [
          _c(
            "div",
            { staticClass: "pricingTable__top pricingTable__top--featured" },
            [
              _c("div", { staticClass: "pricingTable__price" }, [
                _c("p", { staticClass: "pricingTable__currency" }, [
                  _vm._v("$"),
                ]),
                _c("p", { staticClass: "pricingTable__amount" }, [
                  _vm._v(_vm._s(_vm.billYearly ? "99" : "9.99")),
                ]),
                _c("p", { staticClass: "pricingTable__period" }, [
                  _vm._v(_vm._s(_vm.billYearly ? "/yr" : "/mo")),
                ]),
              ]),
              _c("h3", { staticClass: "pricingTable__title" }, [
                _vm._v("Elite"),
              ]),
              _c("p", [_vm._v("Membership")]),
            ]
          ),
          _vm._m(3),
          _vm.hasEliteSubscription
            ? _c("button", { staticClass: "btn btn--currentPlan" }, [
                _vm._v("Current Plan "),
              ])
            : _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.SET_ACTIVE_MODAL({
                        modal: _vm.ModalType.Subscribe,
                        payload: _vm.billYearly,
                      })
                    },
                  },
                },
                [_vm._v("Start Your 30 Day Free Trial")]
              ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h1", [_vm._v("Elite Account")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pricingTable__top" }, [
      _c("div", { staticClass: "pricingTable__price" }, [
        _c("p", { staticClass: "pricingTable__currency" }, [_vm._v("$")]),
        _c("p", { staticClass: "pricingTable__amount" }, [_vm._v("0")]),
      ]),
      _c("h3", { staticClass: "pricingTable__title" }, [_vm._v("Standard")]),
      _c("p", [_vm._v("Membership")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "pricingTable__description" }, [
      _c("li", [_vm._v("Beat Uploads: Up To 50 Beats")]),
      _c("li", [_vm._v("Accept PayPal & Credit Card Payments ")]),
      _c("li", [_vm._v("Discounts & Coupon Codes")]),
      _c("li", [_vm._v("Collaborative Producer Splits")]),
      _c("li", [_vm._v("Sell On TheCharts Marketplace")]),
      _c("li", [_vm._v("Integrated Social Sharing")]),
      _c("li", [_vm._v("Custom Licenses")]),
      _c("li", [_vm._v("Sales Statistics")]),
      _c("li", [_vm._v("Traffic Statistics")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "pricingTable__description" }, [
      _c("li", [
        _vm._v("Beat Uploads: "),
        _c("span", { staticClass: "textHighlight" }, [
          _vm._v("Unlimited Beats"),
        ]),
      ]),
      _c("li", [
        _vm._v("Customizable "),
        _c("span", { staticClass: "textHighlight" }, [_vm._v("Profile")]),
      ]),
      _c("li", [
        _vm._v("Embeddable "),
        _c("span", { staticClass: "textHighlight" }, [
          _vm._v("Amplify Player/Store"),
        ]),
      ]),
      _c("li", [_vm._v("Sell Drum Kits and Sound Packs")]),
      _c("li", [_vm._v("Sell Custom Services")]),
      _c("li", [
        _c("span", { staticClass: "textHighlight" }, [_vm._v("0% Commission")]),
        _vm._v(" On Your Beat Store"),
      ]),
      _c("li", [_vm._v("Accept PayPal & Credit Card Payments")]),
      _c("li", [_vm._v("Discounts & Coupon Codes")]),
      _c("li", [_vm._v("Collaborative Producer Splits")]),
      _c("li", [_vm._v("Sell On TheCharts Marketplace")]),
      _c("li", [_vm._v("Integrated Social Sharing")]),
      _c("li", [_vm._v("Custom Licenses")]),
      _c("li", [_vm._v("Sales Statistics")]),
      _c("li", [_vm._v("Traffic Statistics")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }